import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { OrderExtraService, OrderMailingClass, Resource } from './Base';
import { Contact } from './Contacts';
import { Service as ResourceService } from './Resources';
import { Template } from './Templates';
import { emptyKeysRemoved } from './util';

dayjs.extend(isToday);

export type OrderObjects = typeof OrderObjects[keyof typeof OrderObjects];
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderObjects = {
    LETTER: 'letter',
    POSTCARD: 'postcard',
    CHEQUE: 'cheque',
    SELF_MAILER: 'self_mailer',
} as const;

export enum OrderIMBStatus {
    // If we encounter any scan code (other than a stop-the-clock scancode)
    ENTERED_MAIL_STREAM = 'entered_mail_stream',
    // If we encounter a stop-the-clock scancode
    OUT_FOR_DELIVERY = 'out_for_delivery',
    // If we encounter any scancode with Mail Level "Cancellation"
    RETURNED_TO_SENDER = 'returned_to_sender',
}

export enum OrderStatus {
    READY = 'ready',
    PRINTING = 'printing',
    PROCESSED_FOR_DELIVERY = 'processed_for_delivery',
    COMPLETED = 'completed',
    CANCELLED = 'cancelled',
}

export type OrderCreationFields<T extends Order> = Omit<
    { [K in keyof T]: T[K] extends Contact | Template ? string : T[K] },
    | 'imbStatus'
    | 'url'
    | 'status'
    | 'object'
    | 'id'
    | 'live'
    | 'createdAt'
    | 'updatedAt'
    | 'carrierTracking'
>;

interface CarrierTrackingUpdate {
    message: string;
    status: string;
    createdAt: string;
    source: string;
    trackingLocation: {
        city: string | null;
        provinceOrState: string | null;
        countryCode: string | null;
        postalOrZip: string | null;
    };
}

// In an effort to reduce `undefined` values in our responses I use explicit nulls here.
export interface CarrierTracking {
    // TODO(Apaar): Get an exhaustive list of possibilities for this.
    status: string;
    statusDetail: string;
    estDeliveryDate: string | null;
    signedBy: string | null;
    carrier: string;
    trackingUpdates: CarrierTrackingUpdate[];
}

export interface Cancellation {
    note?: string;
    reason: CancelReason;
    cancelledByUser?: string;
}

export enum CancelReason {
    USER_INITIATED = 'user_initiated',
    INVALID_CONTENT = 'invalid_content',
    INVALID_ORDER_MAILING_CLASS = 'invalid_order_mailing_class',
}

export interface Order extends Resource {
    object: OrderObjects;

    from?: Contact;
    to: Contact;
    status: OrderStatus;
    imbStatus?: OrderIMBStatus;
    sendDate: Date;
    url?: string;
    express?: boolean;
    mailingClass?: OrderMailingClass;
    mergeVariables?: Record<string, any>;

    carrierTracking: CarrierTracking | null;
    cancellation?: Cancellation;
    campaign?: string;
}

export function undefinedIfToday(date: Date | undefined) {
    if (dayjs(date).isToday()) {
        return undefined;
    }
    return date;
}

/**
 * Converts a local file to be in the format our requests expect.
 * If a file of `string` or `undefined` is passed, it is returned as so.
 */
export async function convertPDFForRequest(
    pdf: File | string | undefined
): Promise<string | ArrayBuffer | null | undefined> {
    if (pdf === undefined || typeof pdf === 'string') {
        return pdf;
    }

    return await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(pdf as File);
    });
}

export function batchContactData(
    toContacts: Contact[],
    from: Contact,
    data?: { description?: string; mergeVariables?: Record<string, any> }
) {
    return toContacts.map((c) => ({
        description: data?.description || c.description,
        from: from.id,
        to: c.id,
        mergeVariables: emptyKeysRemoved({
            ...data?.mergeVariables,
            ...c.metadata,
            to: {
                ...data?.mergeVariables?.to,
                ...c,
            },
        }),
        metadata: { postgrid_dashboard: '' },
    }));
}

export class Service<T extends Resource> extends ResourceService<T> {
    async cancel(id: string) {
        return await this.base.fetchAPI<Order>(`/${this.route}/${id}`, {
            method: 'DELETE',
        });
    }

    async progress(id: string) {
        return await this.base.fetchAPI<T>(
            `/${this.route}/${id}/progressions`,
            {
                method: 'POST',
            }
        );
    }
}

export const orderStatusLabel = (
    order: {
        status: Order['status'];
        imbStatus?: Order['imbStatus'];
        carrierTracking?: Order['carrierTracking'];
        updatedAt?: Order['updatedAt'];
    },
    shorten: boolean
) => {
    if (order.carrierTracking) {
        return order.carrierTracking.status.split('_').join(' ');
    }

    if (order?.imbStatus === OrderIMBStatus.OUT_FOR_DELIVERY) {
        if (
            order.status === OrderStatus.COMPLETED ||
            (order.updatedAt &&
                dayjs(order.updatedAt).isBefore(dayjs().subtract(1, 'day')))
        ) {
            // If the `out_for_delivery` status is older than a day, we just mark it as delivered
            return 'Delivered';
        } else {
            return 'Delivering';
        }
    }
    if (order?.imbStatus === OrderIMBStatus.ENTERED_MAIL_STREAM) {
        return 'On Route';
    }

    const parts = order.status.split('_');

    return shorten ? parts[0] : parts.join(' ');
};

export const mailingClassFromLegacyExpressExtraServiceOptions = (
    mailingClass: OrderMailingClass,
    express: boolean,
    extraService: OrderExtraService | null
): OrderMailingClass => {
    if (express) {
        return OrderMailingClass.EXPRESS;
    }

    if (extraService) {
        switch (extraService) {
            case OrderExtraService.CERTIFIED:
                return OrderMailingClass.CERTIFIED;
            case OrderExtraService.REGISTERED:
                return OrderMailingClass.REGISTERED;
            case OrderExtraService.CERTIFIED_RETURN_RECEIPT:
                return OrderMailingClass.CERTIFIED_RETURN_RECEIPT;
        }
    }

    return mailingClass;
};
