import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import {
    PostcardSize,
    useService as usePostcardService,
} from '../services/Postcards';

type SelectPostcardSizeProps = {
    size: PostcardSize;
    onChange: (size: PostcardSize) => void;
    disabled?: boolean;
};

const SelectPostcardSize = ({
    size,
    onChange,
    disabled,
}: SelectPostcardSizeProps) => {
    const service = usePostcardService();

    const [sizes, setSizes] = useState<PostcardSize[]>([]);

    useEffect(() => {
        let isMounted = true;
        (async () => {
            try {
                const capabilities = await service.capabilities();

                if (!isMounted) {
                    return;
                }
                setSizes(capabilities.sizes);
                onChange(capabilities.sizes[0]);
            } catch (err) {
                console.error(err);
            }
        })();

        return () => {
            isMounted = false;
        };
    }, [service, onChange]);

    const readableSize = (size: PostcardSize) => {
        switch (size) {
            case PostcardSize.SIZE_9X6_REDUCED:
                return '9x6 Reduced';
            case PostcardSize.SIZE_11X6_REDUCED:
                return '11x6 Reduced';
            default:
                return size;
        }
    };

    return (
        <Grid container alignItems="center">
            <FormControl component="fieldset">
                <Typography>Size in Inches (Width x Height)</Typography>
                <RadioGroup
                    row
                    value={size}
                    onChange={(_e, v) => onChange(v as PostcardSize)}
                >
                    {sizes.map((size) => (
                        <FormControlLabel
                            value={size}
                            control={
                                <Radio
                                    color="primary"
                                    inputProps={
                                        {
                                            'data-testid': `postcard-${size}`,
                                        } as React.InputHTMLAttributes<HTMLInputElement>
                                    }
                                />
                            }
                            label={readableSize(size)}
                            key={size}
                            disabled={disabled}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </Grid>
    );
};

export default SelectPostcardSize;
