import React, { useState, useEffect } from 'react';

import Grid, { GridProps } from '@mui/material/Grid';

import {
    ChequeSize,
    useService as useChequesService,
} from '../services/Cheques';

import EnumSelect from './EnumSelect';
import TooltipWrapper from './TooltipWrapper';

type SelectChequeSizeProps = Omit<GridProps, 'onChange'> & {
    label: string;
    destinationCountryCode?: string;
    size: ChequeSize | '';
    onChange: (size: ChequeSize | '') => void;
    selectTestID?: string;
    disabled?: boolean;
};

const SelectChequeSize = ({
    label,
    destinationCountryCode,
    size,
    onChange,
    selectTestID,
    disabled,
    ...props
}: SelectChequeSizeProps) => {
    const service = useChequesService();

    const [sizes, setSizes] = useState<ChequeSize[]>([]);

    useEffect(() => {
        let isMounted = true;
        try {
            if (!destinationCountryCode) {
                return onChange('');
            }

            (async () => {
                const capabilities = await service.capabilities(
                    destinationCountryCode
                );

                if (!isMounted) {
                    return;
                }
                setSizes(capabilities.sizes);
                onChange(capabilities.sizes[0]);
            })();
        } catch (err) {
            console.error(err);
        }

        return () => {
            isMounted = false;
        };
    }, [service, destinationCountryCode, onChange]);

    const readableSize = (size: ChequeSize) => {
        switch (size) {
            case ChequeSize.US_LETTER:
                return 'US Letter';
            case ChequeSize.US_LEGAL:
                return 'US Legal';
        }
    };

    return (
        <TooltipWrapper
            title={
                <span>
                    You must select a contact in order to access cheque sizes.
                </span>
            }
            disabled={!destinationCountryCode}
        >
            <Grid item {...props}>
                <EnumSelect
                    title={label}
                    valueLabels={sizes.map((size) => [
                        size,
                        readableSize(size),
                    ])}
                    onChange={onChange}
                    value={size}
                    disabled={disabled || !destinationCountryCode}
                    selectTestID={selectTestID}
                    required
                />
            </Grid>
        </TooltipWrapper>
    );
};

export default SelectChequeSize;
