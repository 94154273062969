import { useMemo } from 'react';

import { useService as useBaseService, ResourceCreateParams } from './Base';
import {
    Order,
    OrderCreationFields,
    Service as OrdersService,
    undefinedIfToday,
} from './Orders';
import { Contact } from './Contacts';
import { requestFormData } from './util';

export enum SelfMailerSize {
    'BIFOLD_8.5X11' = '8.5x11_bifold',
}

export interface SelfMailer extends Order {
    from: Contact;
    insideHTML?: string;
    outsideHTML?: string;
    insideTemplate?: string;
    outsideTemplate?: string;
    size: SelfMailerSize;
}

type CreateParams = {
    sendDate?: Date;
    pdf?: File;
} & ResourceCreateParams &
    OrderCreationFields<SelfMailer>;

class Service extends OrdersService<SelfMailer> {
    async create(params: CreateParams): Promise<SelfMailer> {
        return await this.base.fetchAPI(`/${this.route}`, {
            method: 'POST',
            body: requestFormData({
                ...params,
                sendDate: undefinedIfToday(params.sendDate),
                metadata: { postgrid_dashboard: '' },
            }),
        });
    }
}

export const useService = () => {
    const base = useBaseService();
    return useMemo(() => new Service(base, 'self_mailers'), [base]);
};
