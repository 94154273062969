import { useCallback } from 'react';

import type { ListParams, ListResponse } from '../services/util';
import {
    type Campaign,
    draftSendAndWaitForReadyCampaign,
    useService as useCampaignService,
} from '../services/Campaigns';
import { Contact, label } from '../services/Contacts';
import {
    type Letter,
    useService as useLetterService,
} from '../services/Letters';
import {
    type Postcard,
    useService as usePostcardService,
} from '../services/Postcards';
import {
    type Cheque,
    useService as useChequeService,
} from '../services/Cheques';
import {
    type SelfMailer,
    useService as useSelfMailerService,
} from '../services/SelfMailers';
import type { BulkCreationState } from '../components/CreateOrderControls';
import {
    type ContactOrMailingListData,
    isMailingListValue,
} from '../components/ContactOrCSV';
import useDeleteCampaignResources from './useDeleteCampaignResources';

type Order = Letter | Postcard | Cheque | SelfMailer;

export const retrieveOrdersForCampaign = async (
    campaignID: string,
    listFn: (params: ListParams) => Promise<ListResponse<Order>>
): Promise<Order[]> => {
    const orders: Order[] = [];
    let skip = 0;

    for (;;) {
        const orderList = await listFn({
            skip,
            limit: 100,
            search: `{"campaign": "${campaignID}" }`,
        });

        orders.push(...orderList.data);
        skip += orderList.data.length;

        if (skip >= orderList.totalCount) {
            break;
        }
    }

    return orders;
};

const useCreateOrders = () => {
    const campaignService = useCampaignService();
    const deleteCampaignResources = useDeleteCampaignResources();
    const letterService = useLetterService();
    const postcardService = usePostcardService();
    const chequeService = useChequeService();
    const selfMailerService = useSelfMailerService();

    const orderServiceForCampaign = useCallback(
        (campaign: Campaign) => {
            if (campaign.letterProfile) {
                return letterService;
            }

            if (campaign.postcardProfile) {
                return postcardService;
            }

            if (campaign.chequeProfile) {
                return chequeService;
            }

            if (campaign.selfMailerProfile) {
                return selfMailerService;
            }

            throw new Error(
                'A campaign has no order profile associated with it.'
            );
        },
        [letterService, chequeService, postcardService, selfMailerService]
    );

    const generateCampaignReport = useCallback(
        async (campaign: Campaign): Promise<string> => {
            const orderService = orderServiceForCampaign(campaign);
            const orders = await retrieveOrdersForCampaign(
                campaign.id,
                orderService.list.bind(orderService)
            );

            // Taken from our old bulk send
            const headers = 'ID,To,Status';
            const rows = orders.map((order, idx) => {
                const name = label(order.to);
                const csvSafeName = `"${(
                    name ?? 'Contact at Row: ' + (idx + 1).toString()
                ).replace(/"/g, '\\"')}"`;

                return `${order.id},${csvSafeName},Success`;
            });

            return `${headers}\n${rows.join('\n')}`;
        },
        [orderServiceForCampaign]
    );

    return useCallback(
        async (
            contactOrMailingListData: ContactOrMailingListData,
            creationStateCB: (state: {
                loading?: boolean;
                bulkCreationState?: BulkCreationState;
            }) => void,
            profileCB: () => Promise<{
                profileID: string;
                sendDate: Date;
                defaultSenderID?: string;
            }>,
            orderCB: (contact: Contact) => Promise<void>
        ) => {
            if (!contactOrMailingListData) {
                throw new Error('Contact(s) are required for sending orders.');
            }

            if (isMailingListValue(contactOrMailingListData)) {
                creationStateCB({
                    loading: true,
                    bulkCreationState: { type: 'order_profile' },
                });
                const { profileID, defaultSenderID, sendDate } =
                    await profileCB();

                creationStateCB({
                    bulkCreationState: { type: 'campaign' },
                });

                const profileType = profileID.startsWith('letter')
                    ? 'letterProfile'
                    : profileID.startsWith('postcard')
                    ? 'postcardProfile'
                    : profileID.startsWith('cheque')
                    ? 'chequeProfile'
                    : 'selfMailerProfile';

                const campaign = await campaignService.create({
                    [profileType]: profileID,
                    mailingList: contactOrMailingListData.mailingList.id,
                    defaultSenderContact: defaultSenderID,
                    metadata: { postgrid_dashboard: '' },
                });

                try {
                    await draftSendAndWaitForReadyCampaign(
                        campaign.id,
                        sendDate,
                        campaignService
                    );
                } catch (err) {
                    await deleteCampaignResources(
                        campaign,
                        contactOrMailingListData.mailingListImport.id
                    );
                    creationStateCB({
                        bulkCreationState: {
                            type: 'error',
                            message: (err as Error).message,
                        },
                        loading: false,
                    });
                    throw err;
                }

                const report = await generateCampaignReport(campaign);
                creationStateCB({
                    bulkCreationState: {
                        type: 'campaign_report',
                        report,
                        fileName: `${campaign.id}.csv`,
                    },
                });
            } else {
                creationStateCB({ loading: true });
                await orderCB(contactOrMailingListData);
                creationStateCB({ loading: false });
            }
        },
        [campaignService, deleteCampaignResources, generateCampaignReport]
    );
};

export default useCreateOrders;
