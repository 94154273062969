import React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import GoBackButton from './GoBackButton';
import Button from './Button';
import { downloadData } from '../services/util';

export type BulkCreationState =
    | { type: 'order_profile' }
    | { type: 'campaign' }
    | { type: 'campaign_report'; report: string; fileName: string }
    | { type: 'error'; message: string }
    | null;

const BulkCreationDisplay = ({
    state,
}: {
    state: NonNullable<BulkCreationState>;
}) => {
    if (state.type === 'error') {
        return (
            <Grid item xs={12}>
                <Alert severity="error">
                    <AlertTitle color="error">Error creating orders</AlertTitle>
                    {state.message}
                </Alert>
            </Grid>
        );
    }

    if (state.type === 'campaign_report') {
        return (
            <Grid item xs={12}>
                <Grid container spacing={2} direction="row">
                    <Grid
                        item
                        xs={2}
                        xl={1}
                        alignItems="center"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <CheckCircleIcon
                            color="primary"
                            sx={{ fontSize: 48 }}
                        />
                    </Grid>
                    <Grid item xs={10} xl={11}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    color="primary"
                                    sx={{ fontSize: 20, fontWeight: 600 }}
                                >
                                    Orders Created
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    Your orders have successfully been created.
                                    Download the report to view details about
                                    the created orders.{' '}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() =>
                                        downloadData(
                                            state.report,
                                            state.fileName,
                                            'text/csv'
                                        )
                                    }
                                >
                                    Download Report
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            <Grid item xs={12}>
                <Typography
                    color="primary"
                    sx={{
                        textAlign: 'center',
                        fontSize: 14,
                        fontWeight: 500,
                    }}
                >
                    Creating{' '}
                    {state.type === 'order_profile'
                        ? 'Order Profile'
                        : 'Campaign'}
                    ...
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <LinearProgress color="primary" />
            </Grid>
        </>
    );
};

const CreateOrderControls = ({
    isCreatingOrders,
    disableButtons,
    bulkCreationState,
}: {
    isCreatingOrders: boolean;
    disableButtons?: boolean;
    bulkCreationState: BulkCreationState;
}) => {
    if (bulkCreationState) {
        return (
            <Paper variant="outlined">
                <Box margin={4}>
                    <Grid container spacing={2}>
                        <BulkCreationDisplay state={bulkCreationState} />
                    </Grid>
                </Box>
            </Paper>
        );
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={disableButtons || isCreatingOrders}
                    fullWidth
                    size="large"
                >
                    Create
                </Button>
            </Grid>
            <Grid item xs={2}>
                <GoBackButton
                    color="primary"
                    variant="outlined"
                    disabled={disableButtons || isCreatingOrders}
                    fullWidth
                    size="large"
                >
                    Back
                </GoBackButton>
            </Grid>
        </Grid>
    );
};

export default CreateOrderControls;
