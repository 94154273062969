import React, { useContext } from 'react';

import makeStyles from '@mui/styles/makeStyles';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import {
    Switch,
    Route,
    useRouteMatch,
    RouteProps,
    Redirect,
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import posthog from 'posthog-js';

import Sidenav from '../components/Sidenav';
import { Context as AuthContext } from '../context/Auth';

import Overview from './Overview';
import Analytics from './Analytics';
import Contacts from './Contacts';
import Templates from './Templates';
import BankAccounts from './BankAccounts';
import PDFWizard from './PDFWizard';
import Letters from './Letters';
import Postcards from './Postcards';
import Cheques from './Cheques';
import SelfMailers from './SelfMailers';
import Invoices from './Invoices';
import Payment from './Payment';
import Upgrade from './Upgrade';
import Users from './Users';
import Settings from './Settings';
import Webhooks from './Webhooks';
import ReturnEnvelopes from './ReturnEnvelopes';
import APILogs from './APILogs';
import {
    APILogRoutes,
    BankAccountRoutes,
    PDFWizardRoutes,
    ChequeRoutes,
    ContactRoutes,
    ErrorRoutes,
    IntegrationRoutes,
    LetterRoutes,
    PostcardRoutes,
    ReturnEnvelopeRoutes,
    TemplateRoutes,
    WebhookRoutes,
    SelfMailerRoutes,
    TrackerRoutes,
    SSORoutes,
    LogisticsRoutes,
} from '../routes';
import Integrations from './Integrations';
import Unauthorized from './Unauthorized';
import Trackers from './Trackers';
import LogisticsAnalyticsViews from './LogisticsAnalytics';

const useGridWrapperStyle = makeStyles((theme) => ({
    root: {
        margin: '90px auto 0',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '100%',
        },
    },
}));

const AuthRoute = (props: RouteProps) => {
    const { state } = useContext(AuthContext);
    const { isAuthenticated } = useAuth0();

    if (!state.user) {
        if (isAuthenticated) {
            return <Redirect to={SSORoutes.LOGIN} />;
        }
        return <Redirect to="/login" />;
    } else if (!state.user.isPGAdmin) {
        return <Redirect to="/dashboard" />;
    }

    posthog.identify(
        state.user.id,
        {},
        {
            name: state.user.name,
            email: state.user.email,
            phoneNumber: state.user.phoneNumber,
            accessedLiveMode: false,
            addedPaymentMethod: false,
            hasTestOrders: false,
            hasLiveOrders: false,
        }
    );

    return <Route {...props} />;
};

const Dashboard = () => {
    const match = useRouteMatch();

    const gridWrapper = useGridWrapperStyle();

    return (
        <>
            <Sidenav />
            <Box display="flex">
                <Box flexShrink={0} width="255px" />
                <Box flexGrow={1}>
                    <Container classes={{ root: gridWrapper.root }}>
                        <Box m={4}>
                            <Switch>
                                <Route
                                    exact
                                    path={`${match.path}`}
                                    component={Overview}
                                />
                                <Route
                                    exact
                                    path={`${match.path}/analytics`}
                                    component={Analytics}
                                />
                                <Route
                                    path={WebhookRoutes.HOME}
                                    component={Webhooks}
                                />
                                <Route
                                    path={`${match.path}/invoices`}
                                    component={Invoices}
                                />
                                <Route
                                    path={`${match.path}/payment`}
                                    component={Payment}
                                />
                                <Route
                                    path={`${match.path}/upgrade`}
                                    component={Upgrade}
                                />
                                <Route
                                    path={`${match.path}/users`}
                                    component={Users}
                                />
                                <Route
                                    path={IntegrationRoutes.OVERVIEW}
                                    component={Integrations}
                                />
                                <Route
                                    path={`${match.path}/settings`}
                                    component={Settings}
                                />
                                <Route
                                    path={APILogRoutes.HOME}
                                    component={APILogs}
                                />
                                <Route
                                    path={ContactRoutes.HOME}
                                    component={Contacts}
                                />
                                <Route
                                    path={TemplateRoutes.HOME}
                                    component={Templates}
                                />
                                <Route
                                    path={BankAccountRoutes.HOME}
                                    component={BankAccounts}
                                />
                                <Route
                                    path={ReturnEnvelopeRoutes.HOME}
                                    component={ReturnEnvelopes}
                                />
                                <Route
                                    path={TrackerRoutes.HOME}
                                    component={Trackers}
                                />
                                <Route
                                    path={PDFWizardRoutes.HOME}
                                    component={PDFWizard}
                                />
                                <Route
                                    path={LetterRoutes.HOME}
                                    component={Letters}
                                />
                                <Route
                                    path={PostcardRoutes.HOME}
                                    component={Postcards}
                                />
                                <Route
                                    path={ChequeRoutes.HOME}
                                    component={Cheques}
                                />
                                <Route
                                    path={SelfMailerRoutes.HOME}
                                    component={SelfMailers}
                                />
                                <Route
                                    path={ErrorRoutes.UNAUTHORIZED}
                                    component={Unauthorized}
                                />

                                <AuthRoute path={LogisticsRoutes.ANALYTICS}>
                                    <LogisticsAnalyticsViews />
                                </AuthRoute>
                            </Switch>
                        </Box>
                    </Container>
                </Box>
            </Box>
        </>
    );
};

export default Dashboard;
