import { useCallback } from 'react';

import {
    type Campaign,
    useService as useCampaignService,
} from '../services/Campaigns';
import { useService as useMailingListService } from '../services/MailingLists';
import { useService as useMailingListImportService } from '../services/MailingListImports';
import {
    useLetterProfileService,
    useChequeProfileService,
    usePostcardProfileService,
    useSelfMailerProfileService,
} from '../services/OrderProfiles';

const useDeleteCampaignResources = () => {
    const campaignService = useCampaignService();
    const mailingListService = useMailingListService();
    const mailingListImportService = useMailingListImportService();
    const letterService = useLetterProfileService();
    const chequeService = useChequeProfileService();
    const postcardService = usePostcardProfileService();
    const selfMailerService = useSelfMailerProfileService();

    return useCallback(
        async (campaign: Campaign, mailingListImportID: string) => {
            try {
                await campaignService.delete(campaign.id);
            } catch (e) {
                console.error(e);
            }

            const deletionRes = await Promise.allSettled([
                mailingListImportService.delete(mailingListImportID),
                mailingListService.delete(campaign.mailingList),
                campaign.letterProfile
                    ? letterService.delete(campaign.letterProfile)
                    : null,
                campaign.chequeProfile
                    ? chequeService.delete(campaign.chequeProfile)
                    : null,
                campaign.postcardProfile
                    ? postcardService.delete(campaign.postcardProfile)
                    : null,
                campaign.selfMailerProfile
                    ? selfMailerService.delete(campaign.selfMailerProfile)
                    : null,
            ]);

            for (const result of deletionRes) {
                if (
                    result.status === 'rejected' &&
                    result.reason instanceof Error
                ) {
                    console.error(result.reason.message);
                }
            }
        },
        [
            letterService,
            postcardService,
            chequeService,
            selfMailerService,
            campaignService,
            mailingListService,
            mailingListImportService,
        ]
    );
};

export default useDeleteCampaignResources;
