import React, { FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PostcardRoutes } from '../routes';

import { useTemplateVars, useDefaultVars } from '../hooks/useMergeVars';
import { useRegisterCreateOrderResetFunction } from '../hooks/useRegisterCreateOrderResetFunction';
import useCreateOrders from '../hooks/useCreateOrders';

import { useModeContext } from '../context/Mode';
import { useNotificationContext } from '../context/Notification';
import { useCreatePostcard } from '../context/CreatePostcard';

import {
    CreateParams,
    useService as usePostcardsService,
} from '../services/Postcards';
import { formatMergeVariables, unflatten } from '../services/util';
import { useOrganization } from '../services/Organization';
import { usePostcardProfileService } from '../services/OrderProfiles';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';

import SelectTemplate from '../components/SelectTemplate';
import FileUpload from '../components/FileUpload';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import ContactInput from '../components/ContactInput';
import ContactOrCSV, { isContactValue } from '../components/ContactOrCSV';
import MergeVariablesInput from '../components/MergeVariablesInput';
import ExpressDeliveryCheckbox from '../components/ExpressDeliveryCheckbox';
import MailingClassSelector from '../components/MailingClassSelector';
import SendDate from '../components/SendDate';
import SelectPostcardSize from '../components/SelectPostcardSize';
import CreateOrderControls, {
    BulkCreationState,
} from '../components/CreateOrderControls';
import { mailingClassFromLegacyExpressExtraServiceOptions } from '../services/Orders';

const CreatePostcard = () => {
    const history = useHistory();
    const org = useOrganization([history.location]);
    const service = usePostcardsService();
    const { live } = useModeContext();
    const { dispatchSuccess, dispatchError } = useNotificationContext();
    const profileService = usePostcardProfileService();
    const [bulkCreationState, setBulkCreationState] =
        useState<BulkCreationState>(null);
    const createOrders = useCreateOrders();

    const {
        backTemplate,
        description,
        express,
        file,
        fromContact,
        frontTemplate,
        loading,
        mailingClass,
        mergeVars,
        size,
        setSize,
        setBackTemplate,
        setDescription,
        setExpress,
        setFile,
        setFromContact,
        setFrontTemplate,
        setLoading,
        setMailingClass,
        setMergeVars,
        toContactOrMailingListData,
        setToContactOrMailingListData,
        resetState,
        sendDate,
        setSendDate,
    } = useCreatePostcard();

    useRegisterCreateOrderResetFunction(resetState);

    const defaultVars = useDefaultVars(
        isContactValue(toContactOrMailingListData)
            ? toContactOrMailingListData
            : null,
        fromContact
    );
    const templateVars = useTemplateVars(frontTemplate, backTemplate);
    const disableField = bulkCreationState?.type === 'error' || loading;

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (
            !toContactOrMailingListData ||
            ((!frontTemplate || !backTemplate) && !file)
        ) {
            // This should be impossible given that all of the fields above are required
            return;
        }

        try {
            await createOrders(
                toContactOrMailingListData,
                ({ loading, bulkCreationState }) => {
                    if (loading) {
                        setLoading(loading);
                    }

                    if (bulkCreationState) {
                        setBulkCreationState(bulkCreationState);
                    }
                },
                async () => {
                    const mergeVariables = mergeVars && unflatten(mergeVars);
                    const profile = await profileService.create({
                        backTemplate: backTemplate?.id,
                        frontTemplate: frontTemplate?.id,
                        pdf: file ?? undefined,
                        size,
                        description,
                        mailingClass:
                            mailingClassFromLegacyExpressExtraServiceOptions(
                                mailingClass,
                                express,
                                null
                            ),
                        mergeVariables,
                    });

                    return {
                        profileID: profile.id,
                        sendDate,
                        defaultSenderID: fromContact?.id,
                    };
                },
                async (toContact) => {
                    const postcard: CreateParams = {
                        description: description || toContact.description,
                        size,
                        to: toContact.id,
                        from: fromContact?.id,
                        express,
                        mailingClass: express ? undefined : mailingClass,
                        sendDate: sendDate,
                    };

                    const mergeVariables = formatMergeVariables(mergeVars);

                    if (frontTemplate && backTemplate) {
                        await service.create({
                            ...postcard,
                            frontTemplate: frontTemplate.id,
                            backTemplate: backTemplate.id,
                            mergeVariables,
                        });
                    } else {
                        await service.createUploadPDF({
                            ...postcard,
                            pdf: file!,
                        });
                    }
                    history.push(PostcardRoutes.HOME);
                }
            );
            dispatchSuccess('Created postcards.');
        } catch (e) {
            dispatchError((e as Error).message);
        }
    };

    // TODO: Refactor using ref context and common order components for batches
    return (
        <>
            <TopNav />
            <GridPaper direction="column">
                <Grid item>
                    <Box borderBottom="1px solid #ECECEC" pb={1.5} width="100%">
                        <Typography variant="h5" gutterBottom>
                            Create a Postcard
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box my={3.5}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {live ? (
                                    <Alert variant="outlined" color="warning">
                                        You are in live mode so this postcard
                                        will be printed and delivered.
                                    </Alert>
                                ) : (
                                    <Alert variant="outlined" color="info">
                                        You are in test mode so this postcard
                                        will not actually get sent out.
                                    </Alert>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <form onSubmit={onSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Box height={13}></Box>
                                            <TextField
                                                variant="outlined"
                                                label="Description"
                                                fullWidth
                                                value={description}
                                                disabled={disableField}
                                                onChange={(e) => {
                                                    setDescription(
                                                        e.target.value
                                                    );
                                                }}
                                                inputProps={{
                                                    'data-testid':
                                                        'postcard-description',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectPostcardSize
                                                size={size}
                                                onChange={setSize}
                                                disabled={disableField}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ContactOrCSV
                                                label="To Contact"
                                                value={
                                                    toContactOrMailingListData
                                                }
                                                setValue={
                                                    setToContactOrMailingListData
                                                }
                                                textFieldTestId="postcard-to-contact"
                                                disabled={disableField}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ContactInput
                                                label="From Contact"
                                                contact={fromContact}
                                                setContact={setFromContact}
                                                textFieldTestId="postcard-from-contact"
                                                disabled={disableField}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container spacing={2}>
                                                <Grid item xs>
                                                    <SelectTemplate
                                                        label="Front Template"
                                                        template={frontTemplate}
                                                        setTemplate={
                                                            setFrontTemplate
                                                        }
                                                        required={!file}
                                                        disabled={
                                                            disableField ||
                                                            !!file
                                                        }
                                                        textFieldTestId="postcard-front-template"
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <SelectTemplate
                                                        label="Back Template"
                                                        template={backTemplate}
                                                        setTemplate={
                                                            setBackTemplate
                                                        }
                                                        required={!file}
                                                        disabled={
                                                            disableField ||
                                                            !!file
                                                        }
                                                        textFieldTestId="postcard-back-template"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FileUpload
                                                accept="application/pdf"
                                                label="Upload a PDF"
                                                file={file}
                                                setFile={setFile}
                                                required={
                                                    !frontTemplate &&
                                                    !backTemplate
                                                }
                                                disabled={
                                                    disableField ||
                                                    !!frontTemplate ||
                                                    !!backTemplate
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Collapse
                                                in={templateVars.length > 0}
                                            >
                                                <MergeVariablesInput
                                                    templateVars={templateVars}
                                                    mergeVars={mergeVars}
                                                    setMergeVars={setMergeVars}
                                                    defaultVars={defaultVars}
                                                    disabled={disableField}
                                                />
                                            </Collapse>
                                        </Grid>

                                        <MailingClassSelector
                                            xs={6}
                                            mailingClass={mailingClass}
                                            onChange={setMailingClass}
                                            disabled={disableField || express}
                                            selectTestID="postcard-mailing-class"
                                        />
                                        <SendDate
                                            xs={6}
                                            setSendDate={setSendDate}
                                            sendDate={sendDate}
                                            disabled={disableField}
                                            showSubscriptionPopup={
                                                !org?.stripeSubscription
                                            }
                                        />

                                        <ExpressDeliveryCheckbox
                                            checked={express}
                                            setChecked={setExpress}
                                            checkboxTestID="postcard-express"
                                            disabled={disableField}
                                        />
                                        <Grid item xs={12}>
                                            <CreateOrderControls
                                                bulkCreationState={
                                                    bulkCreationState
                                                }
                                                isCreatingOrders={loading}
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </GridPaper>
        </>
    );
};

export default CreatePostcard;
