import React from 'react';

import dayjs from 'dayjs';

import { GridProps } from '@mui/material/Grid';

import DateSelector from './DateSelector';

export const lastHour = () => {
    const now = dayjs();
    const endOfDay = dayjs().endOf('day');
    return endOfDay.diff(now, 'minutes') < 60;
};

export const minDate = () => {
    if (lastHour()) {
        return dayjs().add(1, 'days').toDate();
    } else {
        return dayjs().endOf('day').toDate();
    }
};

export const isSameSendingDay = (sendDate: Date) =>
    lastHour()
        ? dayjs().add(1, 'day').isSame(sendDate, 'day')
        : dayjs().isSame(sendDate, 'day');

type SendDateProps = Omit<GridProps, 'onChange'> & {
    setSendDate: (date: Date) => void;
    sendDate: Date;
    showSubscriptionPopup?: boolean;
    disabled?: boolean;
};

const SendDate = ({ setSendDate, sendDate, ...props }: SendDateProps) => {
    return (
        <DateSelector
            label="Send Date"
            onChange={setSendDate}
            value={sendDate}
            minDate={minDate()}
            maxDate={dayjs().add(100, 'day').startOf('day').toDate()}
            todayButtonDisabled={lastHour()}
            {...props}
        />
    );
};

export default SendDate;
