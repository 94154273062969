import { useMemo } from 'react';

import {
    useService as useBaseService,
    type Resource,
    type ResourceCreateParams,
} from './Base';
import { Service as DeletableResourceService } from './DeletableResource';

export enum CampaignStatus {
    DRAFTING = 'drafting',
    CHANGES_REQUIRED = 'changes_required',
    CREATING_ORDERS = 'creating_orders',
    DRAFT = 'draft',
    READY = 'ready',
    PRINTING = 'printing',
    PROCESSED_FOR_DELIVERY = 'processed_for_delivery',
}

export enum CampaignErrorType {
    PROCESSING_ERROR = 'processing_error',
    INTERNAL_ERROR = 'internal_error',
}

interface CampaignError {
    type: CampaignErrorType;
    message: string;
}

export interface Campaign extends Resource {
    object: 'campaign';
    status: CampaignStatus;
    defaultSenderContact?: string;
    mailingList: string;
    createdCount: number;
    letterProfile?: string;
    postcardProfile?: string;
    chequeProfile?: string;
    selfMailerProfile?: string;
    orderPreviewURL?: string;
    sendDate: Date;
    errors: CampaignError[];
}

interface CreateParams extends ResourceCreateParams {
    mailingList: string;
    letterProfile?: string;
    postcardProfile?: string;
    chequeProfile?: string;
    selfMailerProfile?: string;
    defaultSenderContact?: string;
}

type UpdateParams = Partial<CreateParams>;

interface SendParams {
    sendDate?: Date | string;
}

export class Service extends DeletableResourceService<Campaign> {
    create(params: CreateParams) {
        return this.base.fetchAPI<Campaign>(this.route, {
            method: 'POST',
            body: params,
        });
    }

    update(id: string, params: UpdateParams) {
        return this.base.fetchAPI<Campaign>(`${this.route}/${id}`, {
            method: 'POST',
            body: params,
        });
    }

    send(id: string, params: SendParams) {
        return this.base.fetchAPI<Campaign>(`${this.route}/${id}/send`, {
            method: 'POST',
            body: params,
        });
    }
}

/**
 * Waits for a campaign to be in the `draft` state and after which, sends
 * the campaign with the provided `sendDate`. This will then wait for the
 * campaign to be in a `ready` state.
 *
 * @throws When the campaign has a status of `changes_required` and throws an
 * error whose message is the errors joined by newlines.
 */
export const draftSendAndWaitForReadyCampaign = async (
    campaignID: string,
    sendDate: Date,
    campaignService: Service
) => {
    const MAX_FETCH_ATTEMPTS = 500;
    const TIMEOUT_INTERVAL = 500;
    for (
        let i = 0;
        i < MAX_FETCH_ATTEMPTS;
        await new Promise((res) => setTimeout(res, TIMEOUT_INTERVAL)), ++i
    ) {
        const campaign = await campaignService.get(campaignID);

        if (campaign.status === CampaignStatus.DRAFT) {
            break;
        }

        if (campaign.status === CampaignStatus.CHANGES_REQUIRED) {
            throw new Error(
                campaign.errors.map((err) => err.message).join('\n')
            );
        }
    }

    await campaignService.send(campaignID, { sendDate });

    for (
        let i = 0;
        i < MAX_FETCH_ATTEMPTS;
        // The actual creation of orders takes much longer so wait longer
        await new Promise((res) => setTimeout(res, TIMEOUT_INTERVAL * 2)), ++i
    ) {
        const campaign = await campaignService.get(campaignID);

        if (campaign.status === CampaignStatus.READY) {
            break;
        }

        if (campaign.status === CampaignStatus.CHANGES_REQUIRED) {
            throw new Error(
                campaign.errors.map((err) => err.message).join('\n')
            );
        }
    }
};

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base, 'campaigns'), [base]);
};
