import React, { PropsWithoutRef, useCallback } from 'react';

import {
    BankAccount,
    label,
    useService as useBankAccountsService,
} from '../services/BankAccounts';

import SearchSelect, { SearchSelectProps } from './SearchSelect';

const SelectBankAccount = (
    props: PropsWithoutRef<{
        label: string;
        account: BankAccount | null;
        setAccount: (account: BankAccount) => void;
        required?: boolean;
        size?: SearchSelectProps['size'];
        textFieldTestId?: string;
        disabled?: boolean;
    }>
) => {
    const service = useBankAccountsService();

    const search = useCallback(
        async (text?: string) => {
            const res = await service.list({
                skip: 0,
                limit: 10,
                search: text,
            });

            return res.data;
        },
        [service]
    );

    return (
        <SearchSelect
            label={props.label}
            value={props.account}
            setValue={(value) => props.setAccount(value)}
            getOptionLabel={(value) => label(value)}
            getOptionSelected={(option, value) => option.id === value.id}
            required={props.required}
            size={props.size}
            search={search}
            disabled={props.disabled}
            resourceName="bank_account"
            textFieldTestId={props.textFieldTestId}
        />
    );
};

export default SelectBankAccount;
